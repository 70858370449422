.document-container {
  background-color: #f1f5f0;
  border-radius: 10px;
}

.document-package-card {
  background: #f9fafa;
}

.sigining-package-card {
  background: #f9fafa;
  /* height: 580px; */
}

.sigining-package-status {
  background-color: #fcc0c0;
  color: black;
}

.sigining-package-card p {
  color: black;
}

.create-document-package {
  background: rgba(97, 145, 48, 0.08);
  border: 1px solid #619130;
  height: 410px;
}

.create-signing-package {
  background: rgba(97, 145, 48, 0.08);
  border: 1px solid #619130;
  height: 580px;
}

.sign-in-package-menu div {
  cursor: pointer;
}

.sign-in-package-btn:active {
  background-color: #f9fafa;
}

.sign-in-package-btn:hover {
  background-color: #f9fafa;
}

.configuration-pages .sidebar {
  background: white;
}

.signingsbutton-header {
  background-color: white;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.form-section {
  background-color: #f0f0f0;
}

.main-form {
  background-color: white;
  border-radius: 8px;
}

.sidebarbtn {
  white-space: wrap;
  height: fit-content !important;
  height: 44px;
  display: flex;
  align-items: left;
  gap: 7px;
  color: black;
  font-size: 13px;
  font-family: Cerebri sans-light;
  border-radius: 6px;
  cursor: pointer;
  text-align: left;
  background-color: #ffffff;
  padding: 11px 16px;
}

.sidebarbtn_active {
  background: #f0f0f0 !important;
  color: black;
  font-size: 13px;
  border-radius: 6px;
  display: flex;
  gap: 7px;
  padding: 11px 16px;
}

.btnone {
  box-shadow: none !important;
  height: 37px !important;
  border: 2px solid #f0f0f0 !important;
  border-radius: 8px 0px 0px 8px !important;
  border: 1px solid #d1d1d1 !important;
  font-size: 12px;
  font-family: Cerebri Sans;
}

.btntwo {
  box-shadow: none !important;
  height: 37px !important;
  border: 1px solid #d1d1d1 !important;
  border-radius: 0px 8px 8px 0px !important;
  font-size: 12px;
  font-family: Cerebri Sans;
}

.btn-group,
.btn-group-vertical {
  box-shadow: none !important;
}

.document-previous:disabled {
  color: #b6b6b6 !important;
}

.added-document-delete-btn {
  background: white;
  border: 1px solid #d1d1d1;
  padding: 0.3rem 0.5rem 0.2rem;
  border-radius: 4px;
}

.added-document-delete-btn:disabled {
  cursor: not-allowed;
}

.upload-manually-document-table table {
  border-spacing: 0 0.5rem;
  border-collapse: separate;
}

/* react select dropdown custom css */
.upload-manually-document-table table .css-qgckm3-indicatorSeparator {
  display: none;
}

.configuration-pages .css-sayndy-indicatorSeparator {
  display: none;
}

.upload-manually-document-table .css-yymjdg-control {
  min-height: 33px;
}

.signingSmallSelect .css-yymjdg-control {
  min-height: 26px !important;
}

/* create new package */
.document-tabs {
  height: 44px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 13px;
  font-family: Cerebri sans-light;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  text-align: center;
  background-color: #f9fafa;
}

.document-tabs-active {
  white-space: nowrap;
  height: 44px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 13px;
  font-family: Cerebri sans-medium;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  text-align: center;
  width: 100%;
  background-color: #fff;
  color: #619130;
}

.global-presettings {
  /* background-color: #ecf1e6; */
  border-radius: 8px;
}

.normalTextstyle {
  border: 1px solid #d1d1d1;
  border-radius: 6px;
}

.normalTextstyle {
  border: 1px solid #d1d1d1 !important;
  outline: none;
}

.normalGroupTextStyle {
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  position: relative;
  width: 100%;
}

.normalGroupTextStyle:focus-visible {
  border: 1px solid #d1d1d1 !important;
  outline: none;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.configuration-pages .Payments-and-rented-checkbox {
  display: flex !important;
  /* justify-content: center !important; */
  align-items: center !important;
  gap: 0.5rem;
}

.configuration-pages .Payments-and-rented-checkbox label {
  font-size: 14px;
  font-family: Cerebri sans-light;
}

.configuration-pages .Payments-and-rented-checkbox :checked {
  background-color: white !important;
  color: #619130;
  outline: none !important;
}

.configuration-pages .form-check-input[type="radio"]:checked:after {
  border-radius: 50%;
  background-color: #619130 !important;
  transition: border-color;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

.configuration-pages .form-check-input {
  width: 20px;
  height: 20px;
}

.configuration-pages .form-check-input:focus {
  border-color: #d1d1d1;
  outline: 0;
  box-shadow: unset;
}

.configuration-pages .form-check-input:checked[type="radio"] {
  background-image: url("../../assets/icons/svg_icons/radioSvg.svg");
  background-size: 9rem;
}

.configuration-pages .form-check-input:checked {
  border-color: #d1d1d1 !important;
  background-color: unset;
}

.document-property-table-btn {
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  width: 30px;
  height: 30px;
}

/* configuration */

.form-check-input:focus:before {
  box-shadow: none !important;
}

.css-1pjngfg-container {
  cursor: not-allowed;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

/* upload document manually input style */

.standard-input {
  border: none;
  outline: none;
  /* Remove the outline when focused */
  background-color: transparent;
  width: 100%;
}

.standard-input:hover {
  border: none;
}

@media (max-width: 1024px) {
  .my-media-query-section {
    flex-direction: column !important;
    align-items: flex-start;
  }
}