.et-dropzone-container-new {
  height: 100px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px dashed #d1d1d1;
  background: #f0f0f0;
  min-width: 444px;
}
.et-dropzone-container-new .fileIcon {
  width: 42px;
  height: 43px;
  border-radius: 6px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.et-dropzone-container-new .et-dropzon-button {
  width: 86px;
  height: 30px;
  border-radius: 100px;
  border: 1px solid #d1d1d1;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}
