@media (hover: hover) {
  .button-placement-close:hover {
    cursor: pointer;
    z-index: 4;
    position: absolute;
    right: -12px;
    bottom: -12px;
  }

  .button-placement:hover {
    cursor: pointer;
    z-index: 4;
    position: absolute;
    right: -12px;
    bottom: -12px;
  }
  .sidebar-button-open:hover {
    height: 24px;
    /* width: 38px; */
    background-color: #d1d1d1;
    border-radius: 100px;
    position: relative;
    /* padding-left: 14px; */
  }
  .sidebar-button-close:hover {
    height: 24px;
    /* width: 38px; */
    background-color: #d1d1d1;
    border-radius: 100px;
    position: relative;
  }
  .button-placement-close:hover {
    cursor: pointer;
    z-index: 4;
    position: absolute;
    right: -140px;
    bottom: -17px;
  }
  .button-placement-open:hover {
    cursor: pointer;
    z-index: 4;
    position: absolute;
    right: -141px;
    bottom: -17px;
  }

  .button-placement:hover {
    cursor: pointer;
    z-index: 4;
    position: absolute;
    right: -12px;
    bottom: -12px;
  }
  .sidebar-button-open:hover {
    height: 24px;
    /* width: 38px; */
    background-color: #d1d1d1;
    border-radius: 100px;
    position: relative;
    /* padding-left: 14px; */
  }
  .sidebar-button-close:hover {
    height: 24px;
    /* width: 38px; */
    background-color: #d1d1d1;
    border-radius: 100px;
    position: relative;
  }
}

.sidebar-button-open {
  height: 24px;
  width: 24px;
  background-color: #f0f0f0;
  border-radius: 100px;
  position: relative;
  border: 1px solid #d1d1d1;
}

.sidebar-button-close {
  height: 24px;
  width: 24px;
  background-color: #f0f0f0;
  border-radius: 100px;
  position: relative;
  border: 1px solid #d1d1d1;
}

.sidebar-button-logo {
  /* position: absolute;
    margin-left: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.close-navigation {
  height: 34px;
  width: max-content;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.button-placement-open {
  cursor: pointer;
  z-index: 4;
  position: absolute;
  right: -22px;
  bottom: -12px;
}
.button-placement-close {
  cursor: pointer;
  z-index: 4;
  position: absolute;
  right: -22px;
  bottom: -12px;
}

.sidebar-button-open {
  height: 24px;
  width: 24px;
  background-color: #f0f0f0;
  border-radius: 100px;
  position: relative;
  border: 1px solid #d1d1d1;
}

.sidebar-button-close {
  height: 24px;
  width: 24px;
  background-color: #f0f0f0;
  border-radius: 100px;
  position: relative;
  border: 1px solid #d1d1d1;
}

.close-navigation {
  height: 34px;
  width: max-content;
  border: 1px solid #f0f0f0;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.css-j0bccu {
  border: none !important;
}

#test {
  transition: background-image 1s ease-in-out;
}
/* Default styles for the sidebar */
.sidebar {
  width: 250px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }
}

/* Media query for even smaller screens */
@media (max-width: 576px) {
  .sidebar {
    width: 100px;
  }
}