.notifications-table table {
  border-collapse: collapse;
  width: 100%;
}
.notifications-table table,
.notifications-table th,
.notifications-table td {
  border: none;
  font-size: 14px;
  font-family: Cerebri sans-light;
  letter-spacing: 0;
  color: #202020;
}
.notifications-table th,
.notifications-table td {
  padding: 0;
  text-align: center;
}
.notifications-table .table-data-left {
  text-align: left;
}
.notifications-table .center-checkbox-field {
  display: flex;
  justify-content: center;
}
