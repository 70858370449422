.Maindiv {
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  min-height: 100vh;
}

.StandardForm {
  /* background-color:#303E4BE5; */
  /* height: 630px; */
  /* width: 478px; */
  border-radius: 8px;
  /* color: white; */
  margin: 0px;
  /* margin-left:60px; */
}

/* .StandardForm input:-webkit-autofill,
.StandardForm input:-webkit-autofill:hover,
.StandardForm input:-webkit-autofill:focus,
.StandardForm input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #ffffff !important;
} */

.WelcomeBio {
  /* padding-top: 30px; */
  font-size: 14px;
  /* color: ; */
  text-align: center;
  padding-top: 20px;
  line-height: 22px;
}

.ForgotPassword {
  display: flex;
  justify-content: flex-end;
}

.small {
  word-spacing: 1px;
}