.messages-contaniner {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  background-color: #f9fafa;
  /* borderTop: "1px solid #F0F0F0"
                  borderRight: "1px solid #F0F0F0"
                  borderBottom: "1px solid #F0F0F0"
                  borderLeft:
                    item.notification == 1
                      ? "5px solid #619130"
                      : "1px solid #F0F0F0"
                  backgroundColor: "#F9FAFA" */
}
