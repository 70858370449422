body {
  /* width: 100vw; */
  /* height: 100vh; */
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: Cerebri sans !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: thin !important;
  /* for Firefox */
  /* overflow-y: hidden;//commented because it affecting new user login page */
  background-color: #d1d1d1 !important;
}

/* ---------- this class used in index.html page and used for managing max width 1920px */
.main-body-wrapper {
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  border-spacing: 0;
}

body::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.scrollable-container-x {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  margin-bottom: 6px !important;
}

.scrollable-container-x::-webkit-scrollbar {
  height: 5px;
  border-radius: 100px;
  overflow: visible;
}

.scrollable-container-x::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
}

.scrollable-container-x::-webkit-scrollbar-track {
  background-clip: padding-box;
}

.scrollable-container-y {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  padding-right: 6px !important;
  /* scrollbar-width: thin; */
}

.scrollable-container-y::-webkit-scrollbar {
  width: 5px;
  border-radius: 100px;
  overflow: visible;
}

.scrollable-container-y::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
}

.scrollable-container-y::-webkit-scrollbar-track {
  background-clip: padding-box;
}

/* SidebarPannel */
.css-dip3t8 {
  position: unset !important;
}

.css-1l5fxv3 {
  border: 1px solid #f0f0f0 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px !important;
  box-sizing: border-box !important;
  width: fit-content !important;
  /* padding: 24px 20px 24px 20px !important; */
  margin-left: 10px !important;
  display: block !important;
}

.css-16jkw2k {
  display: block !important;
}

/* <div className="close-navigation">Close navigation</div> */

.portfolioBorder {
  border-right: 1px solid #f0f0f0;
}

.divider-green {
  flex-grow: 1;
  border: 1px dashed #77c7a1;
  width: 90px;
  margin: 0px;
  position: relative;
  left: 240px;
  bottom: 36px;
}

.toltip-divider-red {
  position: relative;
  left: 280px;
  bottom: 52px;
}

.divider {
  flex-grow: 1;
  border-bottom: 1px dashed #77c7a1;
  /* margin: 5px */
}

.divider-red {
  flex-grow: 1;
  border-bottom: 1px dashed #f1664b;
  /* width: 35px; */
  position: relative;
  bottom: 10px;
}

.line {
  border-bottom: 1px solid grey;
  position: absolute;
  top: 10px;
  width: 100%;
  z-index: -1;
}

@media all and (max-width: 770px) {
  .portfolioBorder {
    border-right: none;
  }
}

.my-hr-line {
  border-bottom-width: 1px;
  border-bottom: 1px solid rgb(240, 240, 240);
  left: -15px;
  width: calc(100% + 30px);
  height: 122px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: rgb(255, 255, 255);
}

td:first-child,
th:first-child {
  border-radius: 4px 0 0 4px;
}

td:last-child,
th:last-child {
  border-radius: 0 4px 4px 0;
}

.MuiInputLabel-root {
  /* font-size: 25px !important;
  padding-bottom: 20px !important; */
  font-family: Cerebri Sans !important;
  /* font-family: Cerebri Sans !important; */
}

.scroll .css-ewdv3l {
  height: 300px;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  margin-right: 6px !important;
}

.scroll .css-ewdv3l::-webkit-scrollbar {
  width: 5px;
  border-radius: 100px;
  overflow: visible;
}

.scroll .css-ewdv3l::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
}

.scroll .css-z5rm24 {
  height: 100px !important;
  overflow-y: hidden !important;
}

.choose-btn {
  border: 1px solid #f0f0f0;
  background-color: #ffff;
  height: 35px;
  color: "#000000";
  border-radius: 8px;
  width: fit-content;
  padding: 0 10px;
  text-transform: uppercase;
  font-family: Cerebri Sans !important;
  font-weight: 500;
  font-size: 12px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none !important;
}

td {
  vertical-align: middle;
  font-weight: 300;
  font-size: 13px;
}

th {
  font-weight: 500;
  font-size: 13px;
}

.cursor-pointer {
  cursor: pointer;
}

.css-z6i5q7-MuiModal-root-MuiPopover-root-MuiMenu-root {
  z-index: 1;
}

.css-tpvds5-MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Calender */
.date-picker-filter {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  padding: 7px 10px 7px 20px;
  color: #000;
  border: 1px solid #d1d1d1;
  min-width: 254px;
  max-width: fit-content;
}

/* react-select dropdown line removing */
.css-qgckm3-indicatorSeparator {
  display: none;
}

.row-class:nth-child(2n) {
  /* Apply styling to every even row */
  background-color: pink !important;
}

.row-class:nth-child(2n + 1) {
  /* Apply styling to every odd row */
  background-color: yellow !important;
}

/* .css-4f1n86-MuiLinearProgress-bar1{
  background-color: #000 !important;
  color : yellow !important;
} */


/* //--------------New table Style--------------// */

.MuiBox-root .css-10gei56 {

  box-shadow: none !important;
}

.css-10gei56 {
  box-shadow: none !important;
}

.css-1mmm5cp-MuiInputBase-root-MuiInput-root-MuiSelect-root {
  font-family: Cerebri sans !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  border: 1px solid #d1d1d1 !important;
  border-radius: 8px !important;
  padding: 4px 5px !important;

}

.css-1mmm5cp-MuiInputBase-root-MuiInput-root-MuiSelect-root:focus {
  background-color: #fff !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
  background-color: #fff !important;
}

.css-1f2oslk-MuiTypography-root {
  font-size: 14px !important;
  font-family: Cerebri sans !important;
}

.css-1nqqeii {
  font-size: 14px !important;
  font-family: Cerebri sans !important;
  border: 1px solid #d1d1d1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

}

.MuiTablePagination-root .MuiBox-root .css-8k4lth {
  display: flex !important;
  justify-content: start !important;
}


/*=============================Time Picker Styles=============================*/

.rc-time-picker-panel-inner {
  margin-top: 43px !important;
  margin-left: 2px !important;
  width: 100% !important;
  border: 1px solid #d1d1d1 !important
}

.my-time-picker input {
  height: 40px !important;
  padding: 12px !important;
  font-size: 14px !important;
  border: 1px solid #d1d1d1 !important;
  border-radius: 8px !important;
  color: #000 !important;
}

.my-error-time-picker input {
  height: 40px !important;
  padding: 12px !important;
  font-size: 14px !important;
  border: 1px solid #e33716 !important;
  border-radius: 8px !important;
  color: #000 !important;
}

.my-error-time-picker :focus-visible {
  border: 1px solid #e33716 !important;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04) inset;
  outline: none !important;
}

.rc-time-picker {
  width: 100% !important;
}

.rc-time-picker-clear {
  display: none;
}

.rc-time-picker-panel-select::-webkit-scrollbar {
  display: none;
}

.my-time-picker :focus-visible {
  border: 1px solid #000 !important;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04) inset;
  outline: none !important;
}

.my-time-picker ::placeholder {
  color: #000 !important;
}

.my-time-picker :disabled::placeholder {
  color: #888 !important;
}

.my-time-picker :disabled {
  background-color: #fff !important;
  cursor: not-allowed !important;
  color: #888888 !important;
  opacity: 0.8 !important;
}

.time-picker-label {
  position: absolute !important;
  left: 12px;
  padding: 0px 4px;
  top: 0px;
  transform: translateY(-50%);
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium;
  color: #000;
  background: #fff !important;
}