.history-container {
  min-height: 317px;
  min-width: 239px;
  border: 1px solid #f4f5f5;
  padding: 20px 35px 20px 20px;
  border-radius: 8px;
  background-color: #fff;
}

.untilidleness-container {
  min-height: fit-content;
  min-width: fit-content;
  border-radius: 8px;
  background-color: #fff;
  border: "1px solid #f4f5f5";
  font-family: Cerebri sans-light;
  font-size: 13px;
}

.daily-btn {
  box-shadow: none !important;
  height: 37px !important;
  border: 2px solid #f0f0f0 !important;
  border-radius: 8px 0px 0px 8px !important;
  border: 1px solid #d1d1d1 !important;
  font-size: 12px;
  font-family: Cerebri Sans;
  text-transform: none !important;
}

.monthly-btn {
  box-shadow: none !important;
  height: 37px !important;
  border: 1px solid #d1d1d1 !important;
  border-radius: 0px 8px 8px 0px !important;
  font-size: 12px;
  font-family: Cerebri Sans;
  text-transform: none !important;
}

.btn-group,
.btn-group-vertical {
  box-shadow: none !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
}

.untilidleness-container {
  min-height: fit-content;
  min-width: fit-content;
  border-radius: 8px;
  background-color: #fff;
  border: "1px solid #f4f5f5";
  font-family: Cerebri sans-light;
  font-size: 13px;
  padding: 0px !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: none !important;
}

.pagination-style {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  border: none;
  padding: 9px;
  cursor: pointer;
}

.unit-process-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 40px 0 0 0;
  flex-wrap: wrap;
}

.footer-color-section {
  height: 8px;
  width: 8px;
  border-radius: 2px;
  align-items: center;
}

.footer-section {
  height: fit-content;
  white-space: nowrap;
  display: flex;
  width: fit-content;
  text-transform: none !important;
  background-color: #f9fafa;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 8px;
  border-radius: 4px;
}
