.eido-tabs.active{
    white-space: nowrap;
    height: 44px;
    align-items: center;
    justify-content: center;
    display:flex;
    font-size:13px;
    font-family: Cerebri sans;
    font-weight: 300;
    border-radius: 8px;
    border:1px solid #F0F0F0;
    cursor: pointer;
    text-align:center;
    padding: 8px 10px ;
    background-color: #fff;
    color:#619130;
    font-weight: 600;
    width: 80px;
  }

  /* color tab css */
  .color-picker {
    border: 1px solid #d1d1d1;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }