.dropdownInput{
    background-color: #FFF;
    color: #000;
    width: 100%;
    display: flex;
    height: 41px;
    border: 1px solid #D1D1D1;
    font-weight: 300;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    border-radius: 8px;
}
.dropdownInput:hover{
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}
.dropdownInput:focus{
    border: 0.5px solid #000 !important
}
.dropdownInput:disabled{
    Color: #888888
}
