/* doc viewer main container */
.rpv-default-layout__container {
  border: 1px solid #d9d9d9;
}
/* sidebar related css */
.rpv-default-layout__sidebar-headers {
  padding-top: 52px;
}
/* big page body section */
.rpv-default-layout__body {
  padding-top: 70px !important;
  background-color: #d9d9d9 !important ;
  padding-bottom: 24px;
}
.rpv-core__inner-page {
  background-color: #d9d9d9 !important ;
  margin-top: 24px;
}

/* top bar function button */

.rpv-default-layout__toolbar {
  background-color: #f9f9f9 !important ;
  justify-content: space-between;
  height: 68px;
}
.rpv-page-navigation__current-page-input {
  width: 35px;
}
.rpv-core__textbox {
  border: none !important;
  outline: none !important;
}
.rpv-core__textbox :focus-visible {
  border: none !important;
  outline: none !important;
}
.rpv-core__textbox :focus-within {
  border: none !important;
  outline: none !important;
}
.rpv-core__textbox :focus {
  border: none !important;
  outline: none !important;
}

/* icons-hover-in-document-preview */
.icons-hover-in-document-preview {
  border-radius: 4px;
  padding: 8px 7px;
  margin-top: 7px;
}
.icons-hover-in-document-preview:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* tooltip used on download, search and print */
.rpv-core__tooltip-body {
  font-size: 14px;
  font-family: Cerebri sans-light;
  border-radius: 8px;
  display: none;
}

/* thumbnail section */
.rpv-default-layout__sidebar-content--ltr {
  padding-top: 50px;
  background: #f2f2f2;
  border-right: "none";
}

@media (min-width: 1024px) {
  .rpv-default-layout__sidebar--opened {
    background: #f2f2f2;
    position: inherit;
    width: 13rem !important;
    z-index: inherit;
  }
}
@media (min-width: 768px) {
  .rpv-default-layout__sidebar--opened {
    width: 13rem !important;
    background: #f2f2f2;
  }
}
