.rbc-btn-group button {
    padding: 30px;
}

.rbc-header {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.units-overview-calendar .rbc-month-view {
    background-color: #ffffff;
    border-radius: 8px;
}

.units-overview-calendar .rbc-toolbar-label {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-weight: 700;
    font-size: 18px;
    padding: 0px !important;
}

.units-overview-calendar .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 0 !important;
}

.units-overview-calendar .rbc-off-range-bg {
    background: none !important;
}

.units-overview-calendar .rbc-today {
    background-color: #ffffff !important;
    /* border-radius: 8px; */
}

.units-overview-calendar .rbc-toolbar {
    padding: 0px 0px 20px 0px !important;
    margin-bottom: 0px !important;
}

.units-overview-calendar .rbc-event {
    padding: 7px 10px !important;
    color: #000000 !important;

    background-color: #BAE2CF !important;
}

.units-overview-calendar .custom-toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.units-overview-calendar .custom-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 14px;
    cursor: pointer;
    font-size: 14px;
}

.units-overview-calendar .custom-button:hover {
    background-color: #0056b3;
}

.units-overview-calendar .rbc-toolbar {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.units-overview-calendar .rbc-btn {
    background-color: #f9fafa !important;
    color: #000000;
    border: none;
    padding: 7px 13px !important;
    cursor: pointer;
    font-size: 14px;
    border-radius: 8px !important;
    border: 1px solid #d1d1d1 !important;
}

.units-overview-calendar .rbc-btn:hover {
    background-color: #ffffff !important;
}

.units-overview-calendar .rbc-btn-nav-prev::before {
    /* content: '<'; */
    font-weight: 700;
}

.units-overview-calendar .rbc-btn-nav-next::after {
    /* content: '>'; */
    font-weight: 700;
}

.units-overview-calendar .rbc-btn-nav-prev {
    border-radius: 8px !important;
    /* border-right: none !important; */
}

.units-overview-calendar .rbc-btn-nav-next {
    border-radius: 8px !important;
}

.units-overview-calendar .new-event {
    background-color: #BAE2CF !important;
    color: #000000 !important;
}

.units-overview-calendar .rbc-day-bg {
    padding: 10px !important;
}

.units-overview-calendar .rbc-date-cell {
    padding-right: 6px !important;
    /* color: #000000 !important; */
    font-weight: 400 !important;
    font-family: Cerebri Sans !important;
}

/* .rbc-row {
      color: #000000 !important;
      font-weight: 400 !important;
      font-family: Cerebri Sans !important;
  
  } */
.units-overview-calendar .rbc-row-segment {
    padding: 0px 6px 4px 6px !important;
}

.units-overview-calendar .rbc-event:focus {
    outline: none !important;
}