/* @import url(./fonts/fonts.css); */

body {
  font-family: Cerebri sans !important;
}

/* Font variants should not be used anyhwere */
.font-light {
  font-family: Cerebri sans-light !important;
}

.font-medium {
  font-family: Cerebri sans-medium !important;
}

.font-semibold {
  font-family: Cerebri sans-semibold !important;
}

.font-bold {
  font-family: Cerebri sans-bold !important;
}

/* ------------Container style css-------------- */
.containerStyle {
  background-color: #e5e7e8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* ------------Container style color -------------- */
.containerStyleColor {
  background-color: #e5e7e8;
}

.bottomBorder {
  border-bottom: 1px sloid #f0f;
}

.whiteContainerStyle {
  border-radius: 8px;
  background: #fff;
}

/* footer-section-button-container */
.created-by {
  font-family: Cerebri sans-light !important;
  color: #888888;
  font-size: 12px;
}

.header-main-title {
  font-family: Cerebri sans-semibold !important;
  font-size: 24px;
}

.container-title {
  font-family: Cerebri sans-bold;
  font-size: 14px;
}

/*-----H5-----*/
.form-label-title {
  font-size: 14px;
  font-family: Cerebri sans-medium;

  letter-spacing: 0;
  color: #252525;
}
.light-font-login {
  font-size: 14px;
  font-family: Cerebri sans-light;
}

/*-----paragraph2Light-----*/
.light-font-text {
  font-size: 14px;
  font-family: Cerebri sans-light;
  letter-spacing: 0;
  color: #202020;
}

.red-light-font-text {
  font-size: 14px;
  font-family: Cerebri sans-light;
  letter-spacing: 0;
  color: #aa1518;
}

.grey-light-font-text {
  font-size: 14px;
  font-family: Cerebri sans-light;
  letter-spacing: 0;
  color: #888888;
}

.border-bottom {
  border-bottom: 1px solid #f0f0f0;
}

.event-section-title {
  font-family: Cerebri sans-semibold !important;
  font-size: 14px;
}

.error-msg {
  font-size: 12px;
  color: #e33716;
  /* color: #e33716; */
  font-family: Cerebri sans-light;
  padding-left: 15px;
  padding-top: 4px;
  margin-bottom: 0px;
}

.desabled {
  cursor: not-allowed;
  opacity: 0.6;
  font-family: Cerebri sans-light;
}

.icon-button {
  background-color: white;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.normal-font-text {
  font-family: Cerebri sans;
}

.table-property-name {
  font-family: Cerebri sans-medium;
  font-size: 13px;
}

.table-grey-text {
  font-family: Cerebri sans-light;
  font-size: 11px;
  color: #888888;
}

/* Control center */
.control-center-header {
  font-family: Cerebri sans-bold;
  font-size: 32px;
  color: #619130;
}

.bgcolor-d-white {
  background-color: #f9fafa;
}

/* Actors css */
.actors-section-title {
  font-family: Cerebri sans-medium;
  font-size: 16px;
}

.actors-label-title {
  font-family: Cerebri sans-medium;
  font-size: 13px;
}

.actors-lite-text {
  font-family: Cerebri sans-light;
  font-size: 13px;
}

/* promotion label tables */

.config-table-heading {
  font-family: Cerebri sans-medium;
  font-size: 12px;
  color: #000000;
}

/* promotion label tables */

.card-body {
  border-radius: 8px;
  background-color: #fff;
  height: fit-content;
}

.css-1gijpgg-MuiTableHead-root {
  opacity: 2 !important;
}

.text-align-center {
  text-align: center;
}

.header-semibold {
  font-size: 14px !important;
  font-family: Cerebri sans-medium !important;
}

.text-left {
  text-align: left !important;
}

.Dropdown-menu-item {
  background-color: #fff;
}

.Dropdown-menu-item:hover {
  background-color: #f0f0f0;
}

.Popups-inner-scrollable-container-height {
  /* Default height for non-XXL screens */
  height: 450px;
}

@media only screen and (min-height: 1080px) {
  /* XXL screen height, remove the height property */
  .Popups-inner-scrollable-container-height {
    height: 700px;
  }
}

/* h1{

 font-size: 32px;
 font-family: Cerebri sans-bold;
 line-height: 48px;
 letter-spacing: 0;
 color: #252525;

}
h2{
  font-size: 24px;
  font-family: Cerebri sans-bold;
  line-height: 30px;
  letter-spacing: 0;
  color: #252525;
}
h3{
  font-size: 20px;
  font-family: Cerebri sans-medium;
  line-height: 24px;
  letter-spacing: 0;
  color: #252525;
}
h4{
  font-size: 16px;
  font-family: Cerebri sans-medium;
  line-height: 22px;
  letter-spacing: 0;
  color: #252525;
}
h5{
  font-size: 14px;
  font-family: Cerebri sans-medium;
 
  letter-spacing: 0;
  color: #252525;
} */
.breadcrumbs {
  font-size: 16px;
  font-family: Cerebri sans-medium;
}
/*----- Class Level Typography ----*/
.paragraph1-light {
  font-size: 16px;
  font-family: Cerebri sans-light;
  line-height: 22px;
  letter-spacing: 0;
  color: #202020;
}

.paragraph1-regular {
  font-size: 16px;
  font-family: Cerebri sans;
  line-height: 22px;
  letter-spacing: 0;
  color: #202020;
}

.paragraph2-light {
  font-size: 14px;
  font-family: Cerebri sans-light;

  letter-spacing: 0;
  color: #202020;
}

.paragraph2-regular {
  font-size: 14px;
  font-family: Cerebri sans;
  line-height: 20px;
  letter-spacing: 0;
  color: #202020;
}

.caption-light {
  font-size: 12px;
  font-family: Cerebri sans-light;
  line-height: 14px;
  letter-spacing: 0;
  color: #1a1a1a;
}

.caption-regular {
  font-size: 12px;
  font-family: Cerebri sans;
  line-height: 14px;
  letter-spacing: 0;
  color: #1a1a1a;
}

.caption-medium {
  font-size: 12px;
  font-family: Cerebri sans-medium;
  line-height: 14px;
  letter-spacing: 0;
  color: #1a1a1a;
}

.label-regular {
  font-size: 12px;
  font-family: Cerebri sans;
  line-height: 14px;
  letter-spacing: 0;
  color: #202020;
}

.label-medium {
  font-size: 12px;
  font-family: Cerebri sans-medium;
  line-height: 14px;
  letter-spacing: 0;
  color: #202020;
}

/* used in disabled  */
.disabled-container {
  pointer-events: none;
  opacity: 0.5;
  /* You can also adjust the opacity to visually indicate it's disabled */
  position: relative;
}

/* Optional: Style to indicate visually that the container is disabled */
.disabled-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* You can change the background color as needed */
  opacity: 0.5;
  z-index: 1;
}

/* used on new user signup page */
.loginForm {
  background: white;
  border-radius: 12px;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.06);
  padding: 1.1rem 0.5rem;
  max-width: 1000px;
  height: 630px;
}

/* used in  user login page */
.mainLoginPageForm {
  border-radius: 8px;
  max-width: 450px;
}

/* OTP modal */
.profile-otp-container {
  display: flex;
  justify-content: flex-start;
}

.profile-otp-input {
  position: relative;
  width: 50px;
  height: 50px;
  /* margin: 0 2px; */
  background-color: transparent;
  font-family: Cerebri sans-bold;
  border: none;
  font-size: 24px;
  text-align: center;
  outline: none;
}

.profile-otp-input:focus {
  background-color: #e4e7e8;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.profile-otp-container .otp-border {
  width: 100%;
  background-color: #d1d1d1;
  height: 4px;
  border-radius: 10px;
}

.profile-otp-input:focus + div {
  background-color: #f1664b;
}

.profile-otp-input:focus {
  border-color: #f1664b;
}

.p-40 {
  padding: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.m-40 {
  margin: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

/* Phone number select style */

.country-phone-number-container {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
}

.country-phone-number-container .phone-number input {
  border: none !important;
  border-left: 1px solid #d1d1d1 !important;
  border-radius: 0 8px 8px 0 !important;
}

.country-phone-number-container .phone-number input:focus {
  outline: none;
  border: hidden;
}

.country-phone-number-container.error {
  border: 1px solid #e33716 !important;
}

.phone-number input:-webkit-autofill,
.phone-number input:-webkit-autofill:hover,
.phone-number input:-webkit-autofill:focus,
.phone-number input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
  background-color: #fff !important;
  /* Set the desired background color */
}

/* hide tabs scrollbar */
.organistaion-tabs::-webkit-scrollbar {
  display: none;
}

.organistaion-tabs {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
