.Reservation-data {
  background-color: #f9fafa;
  border-radius: 6px;
}
.extra-data {
  border-radius: 4px;
  font-family: Cerebri Sans;
  font-weight: 600;
  font-size: 12px;
}
.deposit-text-area {
  background-color: #f9fafa;
  border-radius: 8px;
  /* position: relative !important; */
  /* height: 437px; */
  width: 100%;
  padding-top: 10px;
}
.card-div {
  background-color: #fff599;
  border-radius: 8px;
  /* position: absolute !important; */
  /* z-index: 10 !important; */
  top: 1;
}
.text-area {
  width: 100%;
  /* z-index: 20; */
  /* position: absolute; */
  bottom: 0px;
  background-color: #f9fafa;
  border-top: 1px solid #f0f0f0f0;
  border-radius: 0px 0px 8px 8px;
}
.deposit-input {
  border-bottom: none;
  margin: 0px;
}

.deposit-textfield input {
  border: 1px solid #d1d1d1;
  border-radius: 8px 8px 0px 0px;
  outline: none !important;
  box-shadow: none !important;
}
.deposit-textfield input :focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.deposit-textfield ::placeholder {
  font-size: 14px !important;
  font-weight: 300 !important;
  font-family: Cerebri Sans !important;
  color: #000000 !important;
}
.deposit-textarea input {
  border: 1px solid #d1d1d1 !important;
  border-top: none !important;
  border-radius: 0px 0px 0px 8px !important;
  border-right: none !important;
}

.deposit-textarea textarea {
  border: 1px solid #d1d1d1 !important;
  border-top: none !important;
  border-radius: 0px 0px 0px 8px !important;
  border-right: none !important;
  resize: none !important;
  min-height: 90px !important;
  height: fit-content !important;
  outline: none !important;
  box-shadow: none !important;
}

.deposit-textarea textarea :focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.deposit-textarea ::placeholder {
  font-size: 14px !important;
  font-weight: 300 !important;
  font-family: Cerebri Sans !important;
  color: #000000 !important;
}

.deposit-textarea :hover {
  border-color: none !important;
}

.deposit-textarea :focus {
  border-color: none !important;
}

.deposit-textarea-icon {
  border-radius: 0px 0px 8px 0px !important;
  background-color: #ffffff !important;
  border: none !important;
  border-right: 1px solid #d1d1d1 !important;
  border-bottom: 1px solid #d1d1d1 !important;
  min-height: 90px !important;
  height: fit-content !important;
  align-items: end !important;
}
.notes-textarea textarea {
  border: 1px solid #d1d1d1 !important;
  border-radius: 8px !important;
  border-right: none !important;
  resize: none !important;
  min-height: 30px !important;
  height: fit-content !important;
  outline: none !important;
  box-shadow: none !important;
}
.notes-textarea-icon {
  border-radius: 0px 0px 8px 0px !important;
  background-color: #ffffff !important;
  border: none !important;
  border-right: 1px solid #d1d1d1 !important;
  border-bottom: 1px solid #d1d1d1 !important;
  min-height: 30px !important;
  height: fit-content !important;
  align-items: end !important;
}
.lead-loader-color.progress {
  height: 5px !important;
  border-radius: 5px !important;
}
