/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.6s ease;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion-component {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.4s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion-component:hover,
.active {
  background-color: #f9fafa;
}

/* Style the accordion content title */
.accordion__title {
  /* text-align: left; */
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.4s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  overflow-y: auto;
  transition: max-height 0.6s ease;
}

/* @keyframes max-height {
  from {
    opacity: 0;
    height: 0%;
  }
  to {
    opacity: 1;
    height: 100%;
  }
} */
/* Style the accordion user icon */
.accordain-user-icon {
  background-color: #d1d1d1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  border-radius: 4px;
}

.accordionSidebar {
  background-color: #f9fafa;
  border: 1px solid #d1d1d1;
  border-bottom-width: 0px;
  border-top-width: 0px;
}
.accordionSidebar-hr {
  color: #d1d1d1 !important;
  /* color: rgba(240, 240, 240, 1); */
  height: 1px !important;
  opacity: 1 !important;
}

/* et-accordion */
.et-accordion .card-body {
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
}
.et-accordion .card-header {
  background-color: #f9fafa;
  border-radius: inherit;
}
