.activites-gray-btn {
  font-size: 13px;
  font-family: Cerebri sans;
  font-weight: 300;

  border: none;
  cursor: pointer;
  text-align: center;
  background-color: #f9fafa;
}

.activites-gray-btn:focus {
  background-color: #d1d1d1;
  color: #000;
  font-weight: 600;
  border-radius: 4px;
}


/* actors -> overview -> process timeline */

.process-timeline .process-item {
  position: relative;
}

.process-item .process-icon {
  margin-top: 2rem;
}

.process-content {
  padding-left: 3%;
  margin: 0.5rem 0;
}

.process-icon svg {
  font-size: 2rem;
  color: #D1D1D1;
}

.process-not-completed-line::before {
  background: #D1D1D1;
  content: "";
  height: calc(100% - 2rem);
  left: 15px;
  position: absolute;
  top: 4rem;
  width: 3px;
  z-index: 1;
}

.process-completed-line::after {
  background: #77C7A1;
  content: "";
  height: calc(100% - 2rem);
  left: 15px;
  position: absolute;
  top: 4rem;
  width: 3px;
  z-index: 1;
}

.hour-glass-icon {
  display: flex;
  background: #ffd700;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.hour-glass-icon svg {
  font-size: 1rem;
  color: #A68600;
}

/* actors -> overview -> process timeline */



/* actors -> screening -> acorrdion */

.lead-loader-color.progress {
  height: 7px !important;
  border-radius: 5px !important;
  background-color:#D1D1D1; ;
}

.progress-bar {
  /* background-color: #77C970 !important; */
  background-color: #54A64D !important;
}

.occupation-dropdown .dropdown-toggle {
  position: relative;
  font-size: 0.8rem;
  color: black !important;
  padding-right: 4rem !important;
  background-color: white;
}

.occupation-dropdown .dropdown-toggle::after {
  border: none !important;
  display: none;
}

.occupation-btn-group input:focus {
  border: none;
  outline: none
}

.occupation-btn-group input[type="radio"]:checked+label {
  background-color: #E9F8E8;
  border: 1px solid #54A64D !important;
}

.occupation-btn-group label {
  background-color: white;
}

.selected-field {
  background-color: #E9F8E8 !important;
  border: 1px solid #54A64D !important;
}

.screening-checkbox-color {
  color: #54A64D;
}

.screening-accordion-icons {
  border: 1px solid #D1D1D1 !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: white !important;
  color: black !important;
}

button.screening-accordion-icons {
  position: relative;
}

button.screening-accordion-icons:disabled {
  opacity: 0.2;
}

.screening-icon-wraper {
  top: 50%;
  left: 12px;
  background-color: #54A64D;
  color: white;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0.6rem;
  transform: translate(-50%, -50%);
}

.screening-accordion-icons {
  cursor: pointer;
}

.screening-accordion-icons input {
  display: none;
}

/* actors -> screening -> acorrdion */
/*--------------decline  btn --------------------*/
.decline-btn {

  background-color: #F1664B;
  color: #fff;
  width: 100%;
  white-space: nowrap;
  align-items: center;
  display: flex;
  gap: 7px;
  font-family:  Cerebri sans-medium;
  border: "none";
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  border: none;
  padding: 10px 15px;
  font-size: 20px;
}

/* --------------Communication --------------- */
.msg-input input {
  border: none;
  outline: none;
  font-size: 13px;
  font-weight: 300;
}

.msg-input input:focus {
  outline: none;

}

/* .msg-input input:focus-visible{
  border: 1px solid white;
} */

.icon-btn {
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  background: #FFFFFF;
}
.qa-icon-btn{
  border: 1px solid #D1D1D1;
  border-radius: 6px;
  color: #FFFFFF;
  font-family: Cerebri sans-medium ;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* customers tabs  */
.customers-tabs {
  /* height: 44px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 13px;
  font-family: Cerebri sans;
  font-weight: 300;
  border-radius: 8px;
  border: 1px solid #F0F0F0;
  cursor: pointer;
  text-align: center;
  background-color: #F9FAFA;
  width: 100%; */
  white-space: nowrap;
  height: 44px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 13px;
  font-family: Cerebri sans-light;
  border-radius: 8px;
  border: 1px solid #F0F0F0;
  cursor: pointer;
  text-align: center;
  padding: 11px 0px;
  width: 100%;
  background-color: #F9FAFA;
  color: #000;
}

.customers-tabs:focus {
  background-color: #fff;
  color: #619130;
  font-weight: 600;
}

.customers-tabs-active {
  white-space: nowrap;
  height: 44px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 13px;
  font-family:Cerebri sans-medium;
  border-radius: 8px;
  border: 1px solid #F0F0F0;
  cursor: pointer;
  text-align: center;
  padding: 11px 0px;
  width: 100%;
  background-color: #fff;
  color: #619130;
}

@keyframes customersanimateHeart {

  /* // scale down and scale up faster in irregular intervals to get the throbbing effect */
  0% {
    transform: scale(0.8);
  }

  5% {
    transform: scale(0.9);
  }

  10% {
    transform: scale(0.8);
  }

  15% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(0.8);
  }
}

.customers-tabs-notification {
  background-color: #D00200;
  border-radius: 100%;
  position: absolute;
  bottom: 32px;
  right: 0;
  transform: rotate(45deg);
  animation: customersanimateHeart 1.2s infinite;
}


.details-signing-package {
  background-color: #F9FAFA;
  border-radius: 8px;
  border: 1px solid #F0F0F0;

}

.signing-page-customers-table table {
  border-collapse: separate;
  border-spacing: 0 1rem;
}

.actor-signing-create-package-card {
  background: rgba(97, 145, 48, 0.08);
  border: 1px solid #619130;
  height: 467px;
}

.actor-customer-create-package-card {
  background: rgba(97, 145, 48, 0.08);
  border: 1px solid #619130;
  height: 370px;
}

.signing-page-customers-table table,
.signing-page-add-document-package-table table {
  border-spacing: 0 0.5rem;
  border-collapse: separate;
}

.tenant-dropdown .css-x5b556-placeholder {
  width: max-content;
}

.tenant-social-security-number label {
  top: 0
}


/* signing page */
.actor-signing-card {
  background: #f9fafa;
  /* height: 460px; */
}

.actor-customer-card {
  background: #f9fafa;
  height: 350px;
}


.actor-signing-order-card {
  background: #f9fafa;
  /* height: 530px; */
}

.signing-page-add-document-package-table .css-yymjdg-control,
.actor-signing-document-from-computer .css-yymjdg-control {
  min-height: 28px;
}

.actor-signing-package-textarea:focus {
  outline: none;
  border: none;
}

.Signing-package-send-to-signing {
  background-color: #FFFFFF;
  border-radius: 8px;
}

.Signing-package-send-to-signing-main {
  background-color: #F1F4EF;
  border-radius: 8px;
}

.signing-view_signing-page table span:not(.Mui-checked) svg {
  color: #d1d1d1;
}

.signing-view_signing-page table span.Mui-checked svg {
  color: #619130 !important;
}

.actor-signing-package-from-unit table span:not(.Mui-checked) svg,
.actor-signing-package-from-case table span:not(.Mui-checked) svg {
  color: #d1d1d1;
}

.actor-signing-package-from-unit table span.Mui-checked svg,
.actor-signing-package-from-case table span.Mui-checked svg {
  color: #619130 !important;
}


/* .customer-card {
  width: 100%;
  background-color: #F9FAFA;
  border-radius: 8px;
  border: 1px solid #F0F0F0;
} */


.customer-card {

  width: 100%;
  margin-bottom: 18px;
  background-color: #F9FAFA;
  padding: 24px;
  border: 1px solid #F0F0F0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
}

.detail-row {
  display: flex;

  margin-bottom: 10px;
}

.detail-label {
  flex-basis: 100px;
  font-weight: 600;
  font-size: 14px;
}

.detail-value {
  flex-grow: 1;
  font-size: 14px;
}