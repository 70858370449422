
.redselectMenu{
    align-items: center;
    z-index: 101;
    position: fixed;
    
    background-color:#fff;
    font-family: Cerebri sans;
    font-weight: 300;
    border: 1px solid #F4F5F5;
    border-radius: 8px;
    width: 250px;
}
.reddropdownInput{
    background-color: #FEF3F1;
    color: #888;
    width: 100%;
    display: flex;
    height: 41px;
    border: 1px solid #F1664B;
    font-family: Cerebri sans-light;
    font-weight: 300;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    border-radius: 8px;
}
.reddropdownInput:hover{
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.animation-Container{
    display: flex;
    position: relative;
    bottom: 110px;
    height: 400px;
}