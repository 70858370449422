.search-property-container {
  border: 1px solid #d1d1d1;
  position: absolute;
  top: 50px;
  left: 14px;
  z-index: 10;
  width: 97%;
}
.clear-all-filters {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  width: fit-content;
  height: 22px;
  gap: 8px;
  border-radius: 4px;
  background: #f0f0f0;
}
.filtered-property-badge {
  background-color: #d1d1d1;
  width: fit-content;
  height: 22px;
  display: flex;
  padding: 2px 8px;
  align-items: center;
}
