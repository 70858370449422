.border-bottom {
  border-bottom: 1px solid #f0f0f0;
}
.switch-section {
  border-right: 1px solid #d1d1d1;
  height: 72px;
}
.arrow-section {
  border-right: 1px solid #d1d1d1;
  height: 100%;
}
.condition-popup-container {
  width: 99%;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
}
.field-conditions-container {
  background-color: #f9fafa;
  border: 0px solid #d1d1d1;
  height: 100%;
}
.cross-btn {
  height: 44px;
  width: 44px;
  border-radius: 8px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
}

.edit-choice-li {
  background-color: #f0f0f0;
  height: 70px;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.dnd-placeholder {
  position: absolute;
  background: #888888;
  border-radius: 8px;
  transition: all;
}
