/* notification msg */
@keyframes animateHeart {

  /* // scale down and scale up faster in irregular intervals to get the throbbing effect */
  0% {
    transform: scale(0.8);
  }

  5% {
    transform: scale(0.9);
  }

  10% {
    transform: scale(0.8);
  }

  15% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(0.8);
  }
}

.notification {
  /* background-color:  #D00200; */
  /* border-radius: 100%; */
  position: absolute;
  bottom: 32px;
  transform: rotate(45deg);
  animation: animateHeart 1.2s infinite;
  /* width: 17px;
    height: 17px; */
}

.notes-container {
  background-color: #f9fafa;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}

.notes-card-section {
  height: 800px;
  overflow-y: scroll;
}

.notes-textarea input {

  border: 1px solid #d1d1d1 !important;
  padding: 16px !important;
  border-radius: 8px 0px 0px 8px !important;
  border-right: none !important;
  resize: none !important;
  min-height: 60px !important;
  height: fit-content !important;
  outline: none !important;
  box-shadow: none !important;
}

.notes-textarea textarea {
  border: 1px solid #d1d1d1 !important;
  /* border-top: none !important; */
  border-radius: 8px 0px 0px 8px !important;
  border-right: none !important;
  resize: none !important;
  min-height: 97px !important;
  height: fit-content !important;
  outline: none !important;
  box-shadow: none !important;
}

.notes-textarea textarea :focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.notes-textarea ::placeholder {
  font-size: 14px !important;
  font-weight: 300 !important;
  font-family: Cerebri Sans !important;
  color: #000000 !important;
}

.notes-textarea :hover {
  border-color: none !important;
}

.notes-textarea :focus {
  border-color: none !important;
}

.notes-textarea-icon {
  border-radius: 0px 8px 8px 0px !important;
  background-color: #ffffff !important;
  border-top: 1px solid #d1d1d1 !important;
  border-right: 1px solid #d1d1d1 !important;
  border-bottom: 1px solid #d1d1d1 !important;
  min-height: 133.5px !important;
  height: fit-content !important;
  align-items: end !important;
}

.notes-input-icon {
  padding: 16px !important;
  border-radius: 0px 8px 8px 0px !important;
  background-color: #ffffff !important;
  border-top: 1px solid #d1d1d1 !important;
  border-right: 1px solid #d1d1d1 !important;
  border-bottom: 1px solid #d1d1d1 !important;
  min-height: 60px !important;
  height: fit-content !important;
  align-items: center !important;
}

.documents-table {
  background-color: #f9fafa;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  height: 440px;
}

.customers-card-layout {
  background-color: #f9fafa;
  border: 1px solid #f0f0f0;
}

.message-send-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 24px;
  width: 24px;
  background-color: #619130;
  border-radius: 4px;
  border: none;
}