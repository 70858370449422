.sorting-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 6px;
  border: 1px solid #d1d1d1;
  width: 240px;
  height: 40px;
}
.sorting-container:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}
.sorting-container ul,
li {
  list-style: none;
  -webkit-padding-start: 0;
}
.sorting-container .nav-item {
  display: inline;
}

.sorting-container .nav-item-dropdown {
  position: relative;

  &:hover > .dropdown-menu {
    display: block;
    opacity: 1;
  }
}

.sorting-container .dropdown-trigger {
  position: relative;
  &:focus + .dropdown-menu {
    display: block;
    opacity: 1;
  }
}

.sorting-container .dropdown-menu {
  background-color: #fff;
  display: inline-block;
  text-align: right;
  position: absolute;
  top: 1.2rem;
  right: -11px;
  display: none;
  opacity: 0;
  transition: opacity 0.8s ease;
  width: 240px;
  border-radius: 0px 0px 8px 8px;
  border-top: #fff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.sorting-container .dropdown-menu-item {
  cursor: pointer;
  text-align: left;
}
.sorting-container .option-hover {
  padding: 2px;
}
.sorting-container .option-hover:hover {
  background-color: #f4f4f4;
}
