.cardBorderLeft {
  border-left: 1px solid #000;
  height: 30px;
}
/* .units-tabs-main a.active {
  white-space: nowrap;
  height: 44px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 13px;
  font-family: Cerebri sans-medium;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  text-align: center;
  padding: 8px 10px;
  background-color: #fff;
  color: #619130;
  width: 80px;
} */
/* .units-tabs{
  height: 44px;
  align-items: center;
  justify-content: center;
  display:flex;
  font-size:13px;
  font-family: Cerebri sans-light;
  border-radius: 8px;
  border:1px solid #F0F0F0;
  cursor: pointer;
  text-align:center;
  background-color: #F9FAFA;
  padding: 8px 10px ;
  color: #000;
  width: 80px;
}
.units-tabs:hover{
  color: #000;
} */

/* top bar css */
.topbar-header {
  font-family: Cerebri sans-medium;
}
.grey-info {
  color: #888888;
}
.line-height-20 {
  line-height: 20px;
}
.status-number {
  font-family: Cerebri sans-semibold;
}
.tooltip-header {
  font-family: Cerebri sans-medium;
  font-size: 14px;
}
.tooltip-body-text {
  font-family: Cerebri sans-light;
  font-size: 14px;
}
.status_overview {
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid#F0F0F0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 12px;
  font-family: Cerebri sans-medium;
  cursor: pointer;
}
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* ____________----------------__________________-------------________ */

@media all and (max-width: 770px) {
  .cardBorderLeft {
    border-left: none;
  }
}
