.my-card {
  height: 443px;
  background: #F9FAFA;
}

.main-foo {
  background: #ffffff;
  border-radius: 6px;
  display: flex;
}


.title {
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  font-weight: bold;
  color: #000000;
}
.package-heder {
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.title-pera {
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 0px;
  color: #000000;
}

.end-pera {
  line-height: 22px;
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #000000;
}

.end-title.box {
  position: relative;
  display: flex;
  justify-content: end;
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  font-weight: bold;
  color: #000000;
}

.box {
  float: right;
  display: flex;
  position: relative;
  justify-content: end;
  /* margin-top: -54px; */
}

.ETButton {
  position: relative;
  display: flex;
  justify-content: space-around;
  float: right;
}

.menue {
  background: white;
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  padding-left: 12px;
}
.media-con {
  background-color: #f1f5f0;
  border-radius: 8px;
}
.package-head {
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #888888;
}
.packege-ETButton {
  /* padding-right: 24px; */
  position: relative;
  display: flex;
  justify-content: space-around;
  float: right;
}
.text {
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.add-detail-card {
  box-sizing: border-box;

  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  /* Hvid */

  background: #ffffff;
  /* System colors/WIDGET HORIZONTAL WIDGET GREY */

  border: 1px solid #f0f0f0;
  border-radius: 8px;
}
.bottom-border {
  border-bottom: 1px solid #f0f0f0;
}
.media-container {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  width: 97%;
  border: 1px solid black;
  margin-left: 20px;
  
}
.border-mar {
  margin-left: 0px !important;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f0f0f0;
  padding: 20px 19px;
}
.sub-container {
  height: 106.5% !important;
}
.header-pera {
  margin-bottom: -21px;
}

/* facility tabs */
.facility-tabs{
  height: 44px;
  align-items: center;
  justify-content: center;
  display:flex;
  font-size:13px;
  font-family: Cerebri sans-light;
  border-radius: 8px;
  border:1px solid #F0F0F0;
  cursor: pointer;
  text-align:center;
  background-color: #F9FAFA;
  padding:0 15px ;
}
/* .facility-tabs:focus{
  background-color: #fff;
  color:#619130;
  font-weight: 600;
} */
.facility-tabs-active{
  white-space: nowrap;
  height: 44px;
  align-items: center;
  justify-content: center;
  display:flex;
  font-size:13px;
  font-family: Cerebri sans-medium;
  border-radius: 8px;
  border:1px solid #F0F0F0;
  cursor: pointer;
  text-align:center;
  padding: 11px 0px ;
  width: 100%;
  background-color: #fff;
  color:#619130;
}
.css-1r8wrcl-MuiLinearProgress-bar2 {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transform-origin: left;
  background-color: #619130 !important;
  width: auto;
  -webkit-animation: animation-19gglwu 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
  animation: animation-19gglwu 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
}

.css-qprkun-MuiLinearProgress-root {
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  z-index: 0;
  background-color: #f9fafa !important;
  position: relative;
}


.css-5ir5xx-MuiLinearProgress-bar1 {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transform-origin: left;
  background-color: #619130 !important;
  width: auto;
  -webkit-animation: animation-ozg7p2 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: animation-ozg7p2 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.facility-icon{
  padding: 10px 16px ;
  height: 20px;
  width: 20px;
}
@media only screen and (min-width: 1444px) {
  /* styles for browsers larger than 1440px; */
  .facility-icon{
    padding: 16px 16px ;
    height: 20px;
    width: 20px;
  }
}
@media all and (max-width: 1399px) {
  .facility-icon{
    padding: 8px 14px ;
    height: 18px;
    width: 18px;
  }
}
@media  all and (max-width: 990px) {
  
  .facility-icon{
    padding: 12px 16px ;
    height: 20px;
    width: 22px;
  }
}