.signing-container {
    background: #F1F4EF;
}

.signers-container {
    background-color: #FFFFFF;

}

.add-new-signer-container {
    background: #F1F4EF;

}

.add-new-signer-header {
    background-color: #FFFFFF;
    border-bottom: 1px solid #F0F0F0;
    border-Radius: 8px 8px 0px 0px;
}

.add-new-signer-body {
    background-color: #FFFFFF;
}