.loading-bar {
    position: absolute;
    box-shadow: none;
}

.MainDiv {
    min-height: 280px;
    max-height: fit-content;
    width: 257px;
    padding: 16px;
    border-radius: 8px;
    position: relative;
}

.Card-Name {
    /* margin-top: 9px; */
    /* padding-left: 17px; */
}

.Source-Type {
    margin-top: 5px;
    /* padding-left: 17px; */


}

.Media {
    display: flex;
    flex-direction: column;
    height: 150px;
    width: 100%;
    margin-top: 10px;

    /* justify-content: center;
    align-items: center; */


}

.Media-Package {
    /* margin-left: 17px; */
    /* margin-top: 13px; */
    position: absolute;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
}

.my-drop-menu {
    transform: translate(0px, 50px) !important;
    width: 580px;
}




.css-14vyde3-MuiCollapse-wrapperInner {
    width: 100% !important;
}

