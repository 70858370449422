hr {
  color: #f0f0f0 !important;
  /* color: rgba(240, 240, 240, 1); */
  height: 1px !important;
  opacity: 1 !important;
}

a {
  text-decoration: none !important;
}

.addpropertyCountryHandle {
  border-radius: 8px;
}

.addpropHead {
  font-weight: 500;
  font-family: Cerebri sans;
}

.addpropContent {
  font-family: Cerebri sans-light;
  cursor: pointer;
}

.addpropContent:active {
  background-color: #f9fafa;
}

.addpropertyHr {
  border: 1px solid #f0f0f0;
}

.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  color: #888 !important;

  font-size: 14px !important;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: MediumSeaGreen;
}

.form-check:checked {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

#tooltip-bottom>.tooltip-inner {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #f4f5f5;
  opacity: 1;
  font-size: "14px";
}

#tooltip-bottom>.tooltip-arrow {
  color: #fff;
}

/*--------mui input text style--------- */
.form-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.form-input input::placeholder {
  opacity: 0;
  /* color: #000 !important; */
}

.form-input input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  height: 40px;
  font-size: 14px !important;
  padding: 12px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.form-input label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000000;
  letter-spacing: 0.3px;
  transition: 0.1s;
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.form-input input:focus+label,
.form-input input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium !important;
  color: #000 !important;
  background: rgb(255, 255, 255);

  font-weight: 500;
  padding: 4px;
  /* font-weight: 300;  SV-20230516 - not needed as per figma */
}

/* .form-input input:focus:enabled + label,
.form-input input:not(:placeholder-shown):enabled + label {
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium !important;
  color: #f1664b !important;
  background: rgb(255, 255, 255);
  font-weight: 500;
  padding: 4px;
} */

.form-input input:disabled+label {
  background-color: #fff;
  color: #000 !important;
  font-family: Cerebri sans-light !important;
  /* opacity: 0.6; */
  cursor: not-allowed;
}

.form-input input:focus {
  border: 1px solid #000 !important;
  /* box-shadow:none */
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04) inset;
}

.form-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.form-input input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.form-input input:-webkit-autofill,
.form-input input:-webkit-autofill:hover,
.form-input input:-webkit-autofill:focus,
.form-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

/*-------- mui form-input-error text style -------*/
.form-error-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.form-error-input input {
  border: 1px solid #e33716 !important;
  border-radius: 8px !important;

  width: 100% !important;
  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  height: 40px;
  font-size: 14px !important;
  padding: 12px;
  outline: none;

  box-sizing: border-box;
}

.form-error-input label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000000;
  letter-spacing: 0.3px;
  transition: 0.1s;
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.form-error-input input:focus {
  border: 1px solid #e33716 !important;
}

.form-error-input input:hover {
  border: 1px solid #e33716 !important;
}

.form-error-input input::placeholder {
  opacity: 0;
  border: none !important;

  /* color: #000 !important; */
}

.form-error-input input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.form-error-input input:focus+label,
.form-error-input input:not(:placeholder-shown)+label {
 
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium !important;
  color: #888 !important;
  background: #fff;
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 0) 50%
  ); */
}

.form-error-input input:-webkit-autofill,
.form-error-input input:-webkit-autofill:hover,
.form-error-input input:-webkit-autofill:focus,
.form-error-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

/*------------------ size big input text  ---------------------*/
.form-input-big {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.form-input-big input::placeholder {
  opacity: 0;
}

.form-input-big input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  height: 60px !important;
  font-size: 16px !important;
  letter-spacing: 0.3px;
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.form-input-big label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px !important;
  color: #000;
  letter-spacing: 1px;
  transition: 0.1s;
}

.form-input-big input:focus+label,
.form-input-big input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 12px !important;
  color: #000 !important;
  font-family: Cerebri sans-medium !important;
  letter-spacing: 0.25px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(247, 247, 247, 0) 50%);
  padding: 4px;
}

.form-input-big input:focus {
  border: 1px solid #000 !important;
}

.form-input-big input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.form-input-big input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

/* ---------------------big greeninput -------------------------*/
.big-green-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.big-green-input input::placeholder {
  opacity: 0;
}

.big-green-input input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  height: 60px !important;
  font-size: 20px !important;
  letter-spacing: 0.3px;
  padding: 15px 29px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  background-color: rgba(97, 145, 48, 0.05);
}

.big-green-input label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px !important;
  color: #000;
  letter-spacing: 1px;
  transition: 0.1s;
}

.big-green-input input:focus+label,
.big-green-input input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 11px !important;
  color: #000 !important;
  font-family: Cerebri sans-medium !important;
  letter-spacing: 0.25px;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 55%,
      rgba(247, 247, 247, 0) 45%);
  padding: 4px;
}

.big-green-input input:focus {
  border: 1px solid #000 !important;
  box-shadow: none;
}

.big-green-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.big-green-input input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.big-green-input input:-webkit-autofill,
.big-green-input input:-webkit-autofill:hover,
.big-green-input input:-webkit-autofill:focus,
.big-green-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000000 !important;
}

/* ---------------------big greenerror input -------------------------*/
.big-error-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.big-error-input input::placeholder {
  opacity: 0;
}

.big-error-input input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  height: 60px !important;
  font-size: 20px !important;
  letter-spacing: 0.3px;
  padding: 15px 29px;
  outline: none;
  border: 1px solid #e33716 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  background-color: rgba(217, 83, 79, 0.05);
}

.big-error-input label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px !important;
  color: #000;
  letter-spacing: 1px;
  transition: 0.1s;
}

.big-error-input input:focus+label,
.big-error-input input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 11px !important;
  color: #888 !important;
  font-family: Cerebri sans-medium !important;
  letter-spacing: 0.25px;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 55%,
      rgba(247, 247, 247, 0) 45%);
  padding: 4px;
}

.big-error-input input:focus {
  border: 1px solid #e33716 !important;
}

.big-error-input input:hover {
  border: 1px solid #e33716 !important;
}

.big-error-input input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.big-error-input input:-webkit-autofill,
.big-error-input input:-webkit-autofill:hover,
.big-error-input input:-webkit-autofill:focus,
.big-error-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000000 !important;
}

/* group inputtext text */

/* .group-text:hover{
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
} */
.form-input-group {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.form-input-group input::placeholder {
  opacity: 0;
}

.form-input-group input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  height: 40px;
  letter-spacing: 0.25px;
  font-size: 14px !important;
  padding: 12px !important;
  outline: none;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 8px;
}

.form-input-group label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  letter-spacing: 0.25px;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000;
  transition: 0.1s;
}

.form-input-group input:focus+label,
.form-input-group input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 8px !important;
  color: #888;
  background: rgb(255, 255, 255);
  letter-spacing: 0.25px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(247, 247, 247, 0) 50%);
  padding: 4px;
  font-family: Cerebri sans-medium !important;
}

.form-input-group input:focus {
  border: 1px solid #d1d1d1;
}

.form-input-group input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.form-input-group input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.form-input-group-gray {
  background-color: #f0f0f0;
  border: 1px solid #d1d1d1;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  right: 10px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cerebri sans-semibold;
}

.form-input-group-gray:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

/* mui input text style for edit tag feilds with adornment */
.tag-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.tag-input input::placeholder {
  opacity: 0;
}

.tag-input input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  height: 40px;
  font-size: 14px !important;
  padding: 20px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.tag-input label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000000;
  letter-spacing: 0.3px;
  transition: 0.1s;
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.tag-input input:focus+label,
.tag-input input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium !important;
  color: #888888;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(247, 247, 247, 0) 50%);
  padding: 4px;
  /* font-weight: 300;  SV-20230516 - not needed as per figma */
}

.tag-input input:focus {
  border: 1px solid #888;
}

.tag-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.tag-input input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

/* .react-toastify {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #77c7a1;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #f1664b;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 25%;
  --toastify-toast-background: #f9fafa;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Cerebri sans;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 8px !important;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 0px 0px 0 rgba(0, 0, 0, 0.05) !important;
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border: 1px solid #d1d1d1;
  background-color: var(--toastify-toast-background) !important;
  color: var(--toastify-text-color-light) !important;
} */


.react-toastify {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #77c7a1;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #f1664b;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 40%;
  --toastify-toast-background: #ffffff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Cerebri sans;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

 
  --toastify-color-progress-light: linear-gradient(to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  top: 0% !important;
  padding-top: 0% !important;
}



.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 0px 0px 8px 8px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  background-color: var(--toastify-toast-background) !important;
  color: var(--toastify-text-color-light) !important;
}





.ck-editor__editable {
  /* max-width: 779px; */
  min-height: 100px;
  border-radius: 0px 0px 8px 8px !important;
  font-family: Cerebri sans-light;
  font-size: 14px;
}

/* .ck-editor__top {
  max-width: 779px;
} */

.ck.ck-toolbar {
  border-radius: 8px 8px 0px 0px !important;
  background-color: #f9fafb !important;
}

.dropdown-toggle::after {
  display: none !important;
}

/* custom scroller bar material react table */

.sc-ivnCJf.kdyhDT::-webkit-scrollbar,
.css-11zxvbo-MuiTableContainer-root::-webkit-scrollbar,
.css-zmil4e-MuiTableContainer-root::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  overflow: visible;
}

.sc-ivnCJf.kdyhDT::-webkit-scrollbar-thumb,
.css-11zxvbo-MuiTableContainer-root::-webkit-scrollbar-thumb,
.css-zmil4e-MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  overflow: visible;
}

/* React Select Line hiding */
.css-sayndy-indicatorSeparator {
  display: none;
}

/* Footer section css class */
.footer-section-button-container {
  background-color: #f9fafa;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid #f0f0f0;
}

/* Apply the animation to the loading bar element */
.loading-bar {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #619130;
  z-index: 1;
}

.loading-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #d8ea0c;
  animation: loading 1.3s linear infinite;
}

/* Define the keyframes for the loading bar animation */
@keyframes loading {
  0% {
    left: 50%;
    /* Start from the center */
    width: 0%;
    background-color: #d1d1d1;
  }

  50% {
    right: 50%;
    /* Move to the left */
    width: 50%;
    background-color: #d1d1d1;
  }

  100% {
    left: 0%;
    /* Continue moving to the left */
    width: 100%;
    background-color: #d1d1d1;
  }
}

/* Date part in dashboard sec => staticRange Heading code */

.rdrStaticRanges {
  margin-top: 54px;
}

.rdrStaticRange {
  font-size: 14px !important;
}

.rdrStaticRangeLabel {
  line-height: 14px !important;
}

.dateRangeHeading {
  text-align: center;
  padding-top: 26px;
  width: 180px;
  position: absolute;
  color: #000;
}

.rdrMonthAndYearPickers select {
  padding-top: 5px !important;
}

/* side div */

.rdrDefinedRangesWrapper {
  width: 180px !important;
}

.rdrDateDisplayItemActive {
  border-color: currentColor;
  border-radius: 2px !important;
}

/* Calender */
.date-picker-filter {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  color: #000;
  /* width: max-content; */
  height: 40px;
  width: 100%;
}

.date-picker-filter-new {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  color: #000;
  /* width: max-content; */
  height: 40px;
  width: 100%;
  padding: 7px 10px 7px 20px;
  color: #000;
  border: 1px solid #d1d1d1;
}

.date-picker-filter-new:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.rdrInputRanges {
  display: none !important;
}

.rdrStaticRanges,
.rdrDateDisplayWrapper,
.rdrStaticRange,
.rdrDateRangeWrapper,
.rdrMonthPicker,
.rdrDateDisplayItem input,
.rdrMonthAndYearPickers select {
  font-family: Cerebri Sans !important;
  background-color: #ffffff !important;
}

.rdrStaticRanges {
  align-items: center;
  font-size: 14px;
}

.rdrStaticRange {
  border-bottom: none !important;
}

.css-3xs9pb-MuiTableCell-root {
  background-color: #fff !important;
}

/* ------------------------textField------------------------- */

.icon-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  color: #000000 !important;
}

.icon-input input::placeholder {
  opacity: 0;
}

.icon-input input {
  color: #000 !important;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  height: 44px;
  font-size: 14px !important;
  padding: 12px;

  outline: none;
  background-color: transparent;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  background: #fff !important;
}

.icon-input label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  bottom: 13px;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  font-size: 14px !important;
  color: #000 !important;
  letter-spacing: 0.3px;
  /* transition: 0.1s; */
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.icon-input input:focus+label,
.icon-input input:not(:placeholder-shown)+label {
  display: none;
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium !important;
  /* color: #FFFFFF; */
  background: rgb(234, 234, 234);
  background: linear-gradient(0deg,
      rgba(72, 93, 113, 0) 40%,
      rgb(59, 82, 102) 0%,
      rgba(59, 82, 102) 51%,
      rgba(103, 101, 140, 0.006039915966386533) 0%);
  padding: 4px;
  /* font-weight: 300;  SV-20230516 - not needed as per figma */
}

.icon-input input:focus {
  border: 1px solid #888;
}

.icon-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.icon-input input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}


.icon-input input:-webkit-autofill,
.icon-input input:-webkit-autofill:hover,
.icon-input input:-webkit-autofill:focus,
.icon-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
  background-color: #fff !important;
  /* Set the desired background color */
}
/* css for hiding autofill */
.icon-input input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

.icon-error-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  color: #000000 !important;
}

.icon-error-input input::placeholder {
  opacity: 0;
}

.icon-error-input input {
  color: #000 !important;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  height: 44px;
  font-size: 14px !important;
  padding: 12px;

  outline: none;
  background-color: transparent;
  border: 1px solid #e33716 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  background: #fff !important;
}

.icon-error-input label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  bottom: 13px;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  font-size: 14px !important;
  color: #000 !important;
  letter-spacing: 0.3px;
  /* transition: 0.1s; */
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.icon-error-input input:focus+label,
.icon-error-input input:not(:placeholder-shown)+label {
  display: none;
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium !important;
  /* color: #FFFFFF; */
  background: rgb(234, 234, 234);
  background: linear-gradient(0deg,
      rgba(72, 93, 113, 0) 40%,
      rgb(59, 82, 102) 0%,
      rgba(59, 82, 102) 51%,
      rgba(103, 101, 140, 0.006039915966386533) 0%);
  padding: 4px;
  /* font-weight: 300;  SV-20230516 - not needed as per figma */
}

.icon-error-input input:focus {
  border: 1px solid #e33716 !important;
}

.icon-error-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #e33716 !important;
}

.icon-error-input input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.icon-error-input input:-webkit-autofill,
.icon-error-input input:-webkit-autofill:hover,
.icon-error-input input:-webkit-autofill:focus,
.icon-error-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
  background-color: #fff !important;
  /* Set the desired background color */
}

/* -------------------Filter-Component-Searchfield------------------- */

.filter-component-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.filter-component-input input::placeholder {
  opacity: 0;
}

.filter-component-input input {
  color: #000000;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  /* height: 40px; */
  font-size: 14px !important;
  padding: 12px;
  outline: none;
  background-color: transparent;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.filter-component-input label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000000;
  letter-spacing: 0.3px;
  transition: 0.1s;
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.filter-component-input input:focus+label,
.filter-component-input input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium !important;
  color: #888888;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg,
      rgba(255, 255, 255) 40%,
      rgb(255, 255, 255) 0%,
      rgba(255, 255, 255) 51%,
      rgba(255, 255, 255) 0%);
  padding: 4px;
  /* font-weight: 300;  SV-20230516 - not needed as per figma */
}

.filter-component-input input:focus {
  border: 1px solid #888;
}

.filter-component-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.filter-component-input input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.filter-component-input input:-webkit-autofill,
.filter-component-input input:-webkit-autofill:hover,
.filter-component-input input:-webkit-autofill:focus,
.filter-component-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

/* -------------------grey-input-filter-component------------------- */

.grey-input-filter-component {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.grey-input-filter-component input::placeholder {
  opacity: 0;
}

.grey-input-filter-component input {
  color: #000000;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  /* height: 40px; */
  font-size: 14px !important;
  padding: 12px;
  outline: none;
  background-color: #ffffff;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.grey-input-filter-component label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000000;
  letter-spacing: 0.3px;
  transition: 0.1s;
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.grey-input-filter-component input:focus+label,
.grey-input-filter-component input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium !important;
  color: #888888;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(247, 247, 247, 0) 50%);

  padding: 4px;
  /* font-weight: 300;  SV-20230516 - not needed as per figma */
}

.grey-input-filter-component input:focus {
  border: 1px solid #888;
}

.grey-input-filter-component input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.grey-input-filter-component input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.grey-input-filter-component input:-webkit-autofill,
.grey-input-filter-component input:-webkit-autofill:hover,
.grey-input-filter-component input:-webkit-autofill:focus,
.grey-input-filter-component input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

.total-table table {
  background-color: #ffffff !important;
}

.total-table thead {
  display: none;
}

.total-table th {
  background: #ffffff;
  font-family: Cerebri sans-medium !important;
}

.total-table td {
  font-family: Cerebri sans-medium !important;
  font-size: 14px;
  border-right: none !important;
  border-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 25%,
      rgba(209, 209, 209) 25%,
      rgba(209, 209, 209) 75%,
      rgba(0, 0, 0, 0) 75%) !important;
}

.total-table tr {
  border-radius: 4px;
  background: none !important;
  margin: 4px;
}

.total-table td:nth-last-child(3) {
  border-right: none !important;
}

.total-table td:nth-last-child(2) {
  border: none !important;
  border-left: 1px solid transparent !important;
  background-color: transparent !important;
}

.total-table td:nth-last-child(1) {
  border: none !important;
}

/* div as input */
.badge-input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  /* height: 44px; */
  font-size: 14px !important;
  padding: 12px 16px 11px 16px;
  outline: none;
  background-color: transparent;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  display: flex;
  flex-wrap: wrap;
}

.badge-input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

/* mui normal input text style */
.normal-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.normal-input input::placeholder {
  opacity: 0;
  color: #000 !important;
}

.normal-input input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  height: 40px;
  font-size: 14px !important;
  padding: 11px 50px 12px 12px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.normal-input label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000000;
  letter-spacing: 0.3px;
  transition: 0.1s;
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.normal-input input:focus:enabled+label,
.normal-input input:not(:placeholder-shown):enabled+label {
  font-size: 14px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-light !important;
  color: #000000 !important;
  font-weight: 300;
  padding: 4px;
  display: none;
}

.normal-input input:disabled+label {
  background-color: #fff;
  color: #888 !important;
  font-family: Cerebri sans-medium !important;
  /* opacity: 0.6; */
  cursor: not-allowed;
}

.normal-input input:focus {
  border: 1px solid #000 !important;
  /* box-shadow:none */
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04) inset;
}

.normal-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.normal-input input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.normal-input input:-webkit-autofill,
.normal-input input:-webkit-autofill:hover,
.normal-input input:-webkit-autofill:focus,
.normal-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

/* .form-input input:disabled + label{
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
} */