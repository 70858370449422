@font-face {
  font-family: Cerebri sans;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(./files/Cerebri_Sans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Cerebri sans-light;
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(./files/Cerebri_Sans-Light.ttf) format("truetype");
}

@font-face {
  font-family: Cerebri sans-medium;
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url(./files/Cerebri_Sans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: Cerebri sans-semibold;
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url(./files/Cerebri_Sans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: Cerebri sans-bold;
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url(./files/Cerebri_Sans-Bold.ttf) format("truetype");
}

/* @font-face {
  font-family: Cerebri sans-extrabold;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(./files/Cerebri_Sans-ExtraBold.otf);
}

@font-face {
  font-family: Cerebri sans-heavy;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(./files/Cerebri_Sans-Heavy.otf);
} */

.Cerebri-sans-light {
  font-family: Cerebri sans-light;
}

.Cerebri-sans-bold {
  font-family: Cerebri sans-bold;
}

.Cerebri-sans-medium {
  font-family: Cerebri sans-medium;
}

/* fontweight */

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

/*  */

/* font Size  */
.fs-7 {
  font-size: 0.9rem !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-32 {
  font-size: 32px !important;
}

/* color  */

.hr {
  color: #f0f0f0;
  /* color: rgba(240, 240, 240, 1); */
  height: 1px !important;
  opacity: 1 !important;
}

.blackColor {
  color: #000000;
}

.border {
  border: 1px solid rgba(240, 240, 240, 1);
}

.mainborder {
  border: 1px solid #f4f5f5;
}

.lite-grey-border {
  border: 1px solid #f0f0f0 !important;
}

.bg-grey {
  background-color: rgba(249, 250, 250, 1);
}

.bg-dark-grey {
  background-color: #f0f0f0;
}

.bg-white {
  background-color: #fff;
}

.grey-text {
  color: #888888;
}

.c-black {
  color: #000000;
}

/* .green-text {
  color: #f1664b;
} */

.orange-red-text {
  color: #f1664b !important;
}

.red-text {
  color: red;
}

.white-text {
  color: #fff;
}

/* Radius */

.radius-4 {
  border-radius: 4px;
}

.radius-6 {
  border-radius: 6px;
}

.radius-8 {
  border-radius: 8px;
}
.radius-12 {
  border-radius: 12px;
}

.mx-59 {
  margin-left: 59px;
}

.nowrap {
  white-space: nowrap;
}

.padding-20 {
  padding: 20px;
}
