/* This class is used in property  for portfolio tabs */
.Tabsbtn-component {
  white-space: nowrap;
  height: 44px;
  align-items: center;
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-family: Cerebri sans-light;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  text-align: center;
  background-color: #f9fafa;
  padding: 11px 10px 11px 16px;
}

/* This class is used in property  for portfolio tabs */
.tabsbtnFocusActive {
  white-space: nowrap;
  height: 44px;
  align-items: center;
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-family: Cerebri sans-semibold;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  text-align: center;
  padding: 11px 10px 11px 16px;
  background-color: #fff;
  color: #619130;
  /* font-weight: 500; */
}
