.add-config-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #F1664B;
  padding-top: 13px;
  padding-bottom: 13px;
  border: 1px dashed #f0f0f0;
  background-color: #f9fafa;
  border-radius: 8px;
  cursor: pointer;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 38px solid #d1d1d1;
  border-left: 38px solid #f9fafa;
  border-bottom: 34px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
}

.Promotionlabel-dropdown {
  width: 740px;
  /* height: 220px; */
  border-radius: 8px;
  /* border: 1px solid #d1d1d1; */
}

.Promotionlabel-dropdown-container {
  height: 180px;
  overflow: scroll;
  border: 1px solid #d1d1d1;
  border-radius: 0px 0px 8px 8px !important;
  border-top: none;
}

.config-color-sec {
  height: 17px;
  width: 17px;
  border-radius: 4px;
}

.config-table-data {
  padding-top: 10px;
  /* padding: 10px 10px 0px; */
}
