  .color-picker {
    border: 1px solid #d1d1d1;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .color-picker:hover {
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
  }

  .color-picker-label {
    width: fit-content;
    background-color: #fff;
    position: relative;
    bottom: 4px;
    left: 12px;
    color: #888888;
    font-size: 11px;
    font-family: Cerebri sans-medium;
  }

  .color-picker-input {
    width: 20px;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 1px solid white;
    border-radius: 4px !important;
    height: 20px;
  }

  .label-dropdown {
    width: 740px;
    /* height: 220px; */
    border-radius: 8px;
    /* border: 1px solid #d1d1d1; */


  }

  .label-dropdown-container {
    height: 200px;
    overflow: scroll;
    border: 1px solid #d1d1d1;
    border-radius: 8px !important;
  }