.react-media-tab {
  font-weight: 300;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid transparent;
  margin-right: 8px;
  /* margin-top: 6px; */
  list-style: none;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 13px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  text-align: center;
  background-color: #f0f0f0;
  width: 101px;
}

.medias-container {
  background-color: #f1f5f0;
  border-radius: 10px;
  /* padding: 30px 30px 30px 30px; */
  display: flex;
}

.media-sub-container {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  width: 100%;
}

.media-header {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  /* padding: 16px 17px; */
  width: 100%;
}

.media-tabs {
  display: flex;
  /* padding: 7px 20px 0px 0px; */
  border-bottom: 1px solid #f0f0f0;
  align-items: baseline;
  justify-content: space-between;
}

.sub-media-container {
  /* padding: 30px 60px 30px 60px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.image-container {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 19px;
  flex-direction: row;
  flex-wrap: wrap;
}
.Edit-image-container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 19px;
  flex-direction: row;
  flex-wrap: wrap;
}

.image-card {
  width: 220px;
  /* height: 320px; */
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  border: 1px solid #d1d1d1;
}

.dragIcon {
  position: absolute;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  background-color: #f0f0f0;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.Edit-dragIcon {
  position: absolute;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: #f0f0f0;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image-box {
  display: flex;
  border-radius: 8px 8px 0px 0px;
  height: 50%;
}

.card-bottom {
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 8px 8px;
  height: 50%;
  border-top: 1px solid #d1d1d1;
  /* border-bottom: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1; */
  padding: 12px;
  background-color: #f9fafa;
}
.Edit-card-bottom {
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 8px 8px;
  height: 50%;
  /* border-bottom: 1px solid #D1D1D1; */
  /* border-left: 1px solid #D1D1D1;
  border-right: 1px solid #D1D1D1; */
  padding: 12px;
  background-color: #f9fafa;
  gap: 10px;
}

/* Media Packages CSS */
.media-package-container {
  display: flex;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
}

.package-card {
  min-height: 396px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  background-color: #f9fafa;
}

.media-package-image-container {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7px;
  padding: 17px; */
  /* border-bottom: 1px solid #f0f0f0; */
  height: 186px;
}

.add-tag-btn {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.text-font {
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.add-tag {
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.media-tag {
  display: flex;
  justify-content: space-between;
}

.thumbnail {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100%;
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
}

.thumbnailInner {
  display: flex;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
}

.thumbnailImg {
  display: block;
  width: auto;
  height: auto;
}
