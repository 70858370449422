.borderRed {
  color: #f01818;
}

.bg-border {
  /* border-color: #dc1515; */
  border: 1px solid #f01818;
}

.bg-green {
  background-color: #619130;
}
