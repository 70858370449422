.loading-bar {
  position: absolute;
  box-shadow: none;
}

.MainDiv {
  min-height: 280px;
  max-height: fit-content;
  width: 257px;
  padding: 16px;
  border-radius: 8px;
  position: relative;
}

.Card-Name {
  /* margin-top: 9px; */
  /* padding-left: 17px; */
}

.Source-Type {
  margin-top: 5px;
  /* padding-left: 17px; */
}

.Media {
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 100%;
  margin-top: 10px;

  /* justify-content: center;
    align-items: center; */
}

.Media-Package {
  /* margin-left: 17px; */
  /* margin-top: 13px; */
  position: absolute;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
}

.Media-card-plus-image {
  display: flex;
  height: 72px;
  width: 106px;
  border-radius: 4px;
  position: relative;
  text-align: center;
  color: black;
}
.Media-card-plus-image-center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.accordion-radio-btn {
  display: flex;
  width: 100%;
  padding: 11px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background-color: #d1d1d1;
  border-radius: 8px;
  text-transform: uppercase;
}

