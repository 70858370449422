/* .round {
    position: relative;
  }
  
  .bg-white label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    height: 17px;
    left: 0;
    position: absolute;
    top: 0;
    width: 17px;
  }
  .bg-login label {
    background-color: transparent;
    border: 1px solid #F4F5F5;
    border-radius: 4px;
    cursor: pointer;
    height: 17px;
    left: 0;
    position: absolute;
    top: 0;
    width: 17px;
  }
  
  .tick-white label:after {
    border: 1.5px solid #ffffff;
    border-top: none;
    border-right: none;
    border-width: 1.5px;
    border-radius: 1px;
    content: "";
    height: 6px;
    left: 2px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: rotate(-55deg);
    width: 12px;
  }
  .tick-login label:after {
    border: 1.5px solid #F1664B;
    border-top: none;
    border-right: none;
    border-width: 1.5px;
    border-radius: 1px;
    content: "";
    height: 6px;
    left: 2px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: rotate(-55deg);
    width: 12px;
  }
  
  .round input[type="checkbox"] {
    visibility: hidden;
  }
  
  .after-bg-green input[type="checkbox"]:checked + label {
    background-color: #619130;
    border-color: #619130;
  }
  .after-bg-login input[type="checkbox"]:checked + label {
    background-color: #303E4B;
    border-color: #F4F5F5;
  }
  
  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
  
  .display-inline{
    display: inline-block;
  }
  
   */

/* input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 14px;
  color: red !important;
  height: 14px;
  border: 1px solid white;
  border-radius: 4px;
} */

/* input[type="checkbox"]:checked { */
/* background-color: transparent; */
/* content: "\2713"; */
/* color: green !important; */
/* border-color: rgb(185, 16, 16); */
/* } */

/* input[type="checkbox"]:checked::after {
  /* background-color: transparent; */
/* display: none; */
/* content: "\2713"; */
/* font-family: "Font Awesome 5 Free"; */
/* color: red !important; */
/* font-weight: 900; */
/* font-size: 50px; */

/* border-color: rgb(185, 16, 16); */
/* } */

/* label {
  margin-left: 5px;
  color: #333;
} */

.checklabel {

  position: relative;
  top: 1px;
  width: 16px;
  height: 16px;
  border: 0.5px solid white;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  background-color: transparent;
}

.checklabel:after {

  content: "";
  font-family: Cerebri sans-light;
  position: absolute;
  left: 5.4px;
  top: 1px;
  width: 5px;
  height: 12px;
  border-bottom-right-radius: 2px;
  border-right: 1.7px solid #FFFFFF;
  border-bottom: 1.7px solid #FFFFFF;
  transform: rotate(35deg) scale(1);
  opacity: 0;

}

.styled-checkbox:checked~.checklabel {
  background-color: #DC5f47;
  border: none;
}

.styled-checkbox:checked~.checklabel::after {
  opacity: 1;
  transform: rotate(35deg)scale(1);
}

.styled-checkbox {
  display: none;
}