.radiant {
  position: absolute;
  width: 340px;
  height: 150px;
  top: 0;
  left: 0;
  /* background-color: red; */
  z-index: 999;
  background: radial-gradient(
    92.67% 92.67% at 50% 50%,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 100%
  );
  border-radius: 6px;
}

.Tabsbtn {
  white-space: nowrap;
  height: 44px;
  align-items: center;
  display: flex;
  gap: 7px;
  font-size: 14px;
  font-family: Cerebri sans;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  text-align: center;
  background-color: #ffffff;
  padding: 11px 16px;
  width: fit-content;
  font-weight: 400;
}
.Tabsbtn:focus {
  background-color: #f0f0f0;
  color: #619130;
  font-weight: 500;
}

.TabsbtnFocus {
  white-space: nowrap;
  height: 44px;
  align-items: center;
  display: flex;
  gap: 7px;
  font-size: 14px;
  font-family: Cerebri sans;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  text-align: center;
  padding: 11px 16px;
  background-color: #f0f0f0;
  color: #619130;
  font-weight: 500;
}
