body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.addpropertyMainDiv{
    padding-left: 59px;
}
.mainTopHeading{
    font-weight: 600;
   
    line-height: 36px;
    padding-top: 17px;
    margin-bottom: 0px;
}
.main-Sub-TopHeading-div{
   margin-top: 6px;
}
.main-Sub-TopHeading-div-span1{
   margin-top: 0px;
   font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
}
.main-Sub-TopHeading-div-span3{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #619130
}
	

.filtered-list {
    /* position: absolute; */
     position: "relative";
    cursor: pointer;
    width: 100%;
    background-color:#ffffff;
    border: 1px solid #ddd;
    max-height: 150px;
    overflow-y: auto;
    padding: 5px;
    border-radius: 8px;
    max-height: 116px;


  }

  
