.header {
    /* justify-content: space-between; */
    display: flex;
    margin-top: 12px;
    padding-left: 12px;

}
.media{
    font-family: 'Cerebri Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
}
.customtooltip{
    padding-left: 12px;
}

.pera{
    font-size: 12px;
    color: rgb(136, 136, 136);
    font-weight: 300;
    margin-left: 12px;
}


/* .form-input {
    position: relative;
    width: 70%;
  }
  
  .form-input input::placeholder {
    opacity: 0;
  }
  
  .form-input input {
    width: 40%;
    height: 40px;
    font-size: 14px;
    padding: 10px;
    outline: none;
    border: 1px solid gray;
    box-sizing: border-box;
    border-radius: 5px;
    margin-left:12px ;
  }
  
  .form-input label {
    position: absolute;
    left: 21px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 11px;
    color: gray;
    letter-spacing: 1px;
     transition: .3s;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
  }
  
  .form-input input:focus + label,
  .form-input input:not(:placeholder-shown) + label 
  {
    top: 0;
    font-size: 11px;
    color: gray;
    background: #fff;
    padding: 7px;
  }
  
  .form-input input:focus {
    border: 1px solid gray;
  } */

  /* .row>* {
    flex-shrink: 0;
    width: 4.7%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
} */